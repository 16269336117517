<template>
  <div class="indexScreen" id="screenTotal">
    <div id="myMap">
      <MapContainer></MapContainer>

      <div class="link-btn">
        <div class="link-btn-item" v-for="(item,index) in linkList" :key="index" @click="handleLink(item.url)">{{ item.text }}</div>
      </div>
    </div>

    <div id="header">
      <p>{{nameScreen}}</p>
      <div class="date">
        <span>{{text}}</span>
        <span>{{temp}}℃</span>
        <!-- <span>{{windDir}}{{windScale}}级</span> -->

      </div>
      <div  class="weather">
        <!-- <span>{{week}}</span> -->
        <span>{{nowDate}}</span>
      </div>
      <div class="user" @click="toBack()">
        <img  src="../../../assets/index/user.png" >
        <span>{{user}}</span>
      </div>
    </div>
    <div id="main">

      <div id="mainBox">
        <div id="lefts" :class="getFlag">
          <div class="leftBoxs">
            <div class="villageInfo">
              <div class="boxTitile">
                <span>基地介绍</span>
                <el-select v-model="base" class="base-select" :popper-append-to-body="false"  @change="handlerSelect">
                  <el-option class="option" v-for="item in baseList" :key="item.id" :label="item.baseName" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="jidi">
                <div class="carousel">
                  <el-carousel :interval="3000" :autoplay="false" indicator-position="outside">
                    <el-carousel-item v-for="item, index in imgList" :key="index">
                      <!-- <div class="imgBox"> -->
                      <img :src="item" alt="" @click="carouselChange(index)">
                      <!-- </div> -->
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="jidi-text">
                  {{ baseObj.introduce }}
                </div>
              </div>
            </div>
            <div class="survey">
              <div class="boxTitile">
                <span>设备在线</span>
              </div>
              <div class="surveyBox">
                <div class="holdsAll">
                  <p>设备总数： <span>{{totalNumber}}</span></p>
                </div>
                <div class="areaAll">
                  <p>在线：<span class="total-color">{{onlineNumber}}</span></p>
                </div>
                <div class="peopleAll">
                  <p>离线：<span>{{offlineNumber}}</span></p>
                </div>
              </div>
              <div id="echart" style="width:110%;height:23vh"></div>
            </div>
            <div class="partyBuilding">
              <div class="boxTitile">
                <span>报警记录</span>
              </div>
              <!-- <div class="links">进入主页</div> -->
              <div class="partyBox">
                <div class="leftCard">
                  <div class="member-card card-bg">
                    <div class="memberAll">
                      <p class="memberTop">序号</p>
                    </div>
                    <div class="fullMember">
                      <p class="memberTop">报警时间</p>
                    </div>
                    <div class="memberTwo">
                      <p class="memberTop">报警设备</p>
                    </div>
                    <div class="memberTwo">
                      <p class="memberTop">报警值</p>
                    </div>
                  </div>
                  <div class="alarm-scroll">
                    <div class="member-card member-bg" v-for="(item,i) in warnList" :key="item.id">
                      <div class="memberAll">
                        <p class="memberTop">{{ i+1 }}</p>
                      </div>
                      <div class="fullMember">
                        <p class="memberTop">{{ item.createTime}}</p>
                      </div>
                      <div class="memberTwo">
                        <p class="memberTop">{{ item.deviceName }}</p>
                      </div>
                      <div class="memberTwo">
                        <p class="memberTop alarm-num">{{ item.warnData}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <!-- <div id="myMap">
              <Map :path="villageList"></Map>
          </div> -->

          <div class="between">
            <div class="villageInfo" style="width: 92%;margin-right: 3%;">
              <div class="boxTitile">
                <span>虫情监测数据</span>
              </div>
              <!--                    <div class="links">大屏</div>-->
              <!--                    <div><el-date-picker
                                        v-model="value1"
                                        type="datetimerange"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :default-time="['12:00:00']">
                                      </el-date-picker></div>-->
              <div class="jidi arrow">

                <div class="carousel-detail">
                  <img :src="'http://insect.sennor.net:1881/openFile/'+insectObj.taggingImgUrlCompress" alt="" >
                </div>
                <div class="jidi-text">
                  <div class="equit"><span class="arrow-bg"></span><span class="arrow-key">设备名称:</span><span class="arrow-val">{{insectObj.deviceName}}</span></div>
                  <div class="equit"><span class="arrow-bg"></span><span class="arrow-key">设备编号:</span><span class="arrow-val">{{insectObj.deviceNumber}}</span></div>
                  <div class="equit"><span class="arrow-bg"></span><span class="arrow-key">负责人:</span><span class="arrow-val">{{ baseObj.basePrincipal }}</span></div>
                  <div class="equit"><span class="arrow-bg"></span><span class="arrow-key">经纬度:</span><span class="arrow-val">{{  insectObj.deviceLocation}}</span></div>
                  <div class="equit"><span class="arrow-bg"></span><span class="arrow-key">识别内容:</span><span class="arrow-val" :title="insectObj.results">{{ insectObj.results }}</span></div>
                  <div class="update">{{insectObj.newTime}}更新</div>
                </div>
              </div>
            </div>
            <div class="villageInfo" style="width: 92%">
              <div class="boxTitile">
                <span>灌溉控制设备</span>
              </div>
              <!--                      <div class="links">大屏</div>-->
              <div class="jidi guan">
                <div class="guan-list" v-for="(item,i) in irrigationList" :key="item.itemId">
                  <div class="content">
                    <div>
                      <p><span class="number">{{ item.name }}</span></p>
                    </div>
                    <div class="switch">
                      <span @click="changStatus('255',item.dataPointRelId)"><img :src="switchList[0]"><i class="open">开启</i> </span>
                      <span @click="changStatus('00',item.dataPointRelId)"><img :src="switchList[1]"><i class="close">关闭</i> </span>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div id="rights" :class="!threeFlag3 ? 'translations2' : ''">
          <div class="rightBox">

            <div class="villageInfo">
              <div class="boxTitile">
                <span>土壤监测数据</span>
                <el-select v-model="soildName" :popper-append-to-body="false" class="base-select" @change="chooseEqment">
                  <el-option v-for="item in soilList" :key="item.deviceNum" :label="item.deviceName" :value="item.deviceNum"></el-option>
                </el-select>
              </div>
              <div class="jidi">
                <div class="land">
                  <div class="land-list">
                    <div class="equitment" v-for="item, i in soildObj" :key="i">
                      <div class="text-gray">{{item.name}}</div>
                      <div class="text-white"><span class="number">{{item.data}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="survey">
              <div class="boxTitile">
                <span>气象设备</span>
              </div>
              <!--                <div class="links">大屏</div>-->
              <div class="common-box">
                <div class="land-list">
                  <div class="equitment" v-for="item, i in meteorObj" :key="i">
                    <div class="land-icon icon1"><img :src="item.img"></div>
                    <div class="land-value">
                      <div class="text-gray">{{item.name}}</div>
                      <div class="text-white"><span class="number">{{item.data}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="partyBuilding">
              <div class="boxTitile">
                <span>监控摄像头</span>
              </div>
              <div class="links" @click="toMonit">大屏</div>
              <div class="partyBox">
                <div class="imgInfo">
                  <div class="carouselBig">

                    <video
                        v-if="videoUrl"
                        id="my-video"
                        ref="myVideo"
                        class="video-js vjs-default-skin box"
                        controls
                        preload="auto"
                        loop
                        muted
                        style="height: 100%; width: 100%;object-fit: fill;"
                    >
                      <source :src="videoUrl" type='rtmp/flv' />
                    </video>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottoms"></div> <img v-else :src="imgList[indexs]" alt="">   type="application/x-mpegURL" -->
    <!-- 图片弹框 -->
    <div class="imgBigBox" v-if="imgFlag">
      <div class="popUpTop">
        <span>乡村图片</span>

        <span class="closeCss" @click="closeBox">x</span>
      </div>
      <div class="imgInfo">
        <div class="carouselBig">
          <!-- <el-carousel :interval="3000" type="card" indicator-position="none">
            <el-carousel-item v-for="item, index in imgList" :key="index">
                <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel> -->

          <video
              v-if="indexs == 0"
              id="my-video"
              class="video-js vjs-default-skin box"
              controls
              preload="auto"
              muted
              style="height: 100%; width: 100%;object-fit: fill;"
          >
            <source src="" type="application/x-mpegURL" />
          </video>
          <img v-else :src="imgList[indexs]" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from '../dalixian/map.vue'
import * as echarts from "echarts";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
//import Map_niuwuzhen from "@/views/bigScreen/map_niuwuzhen";
import MapContainer from "@/views/bigScreen/sixianzhen/MapContainer";



export default {
  components:{MapContainer, Map},
  data() {
    return {
      dates: '20:22:22',
      week: '2024 05-19',
      weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
      weathers: [],
      text: '晴',
      temp: '12-18',
      windDir: '',
      windScale: '',
      imgList: [
        require("../../../assets/QB_sixianzhen/sixian3.jpg"),
        require("../../../assets/QB_sixianzhen/sixian2.jpg"),

      ],
      totalArea: '',
      partyMemberNumber: '',
      officialPartyMemberNumber: '',
      probationaryPartyMemberNumber: '',
      totalNumber: '',
      onlineNumber: '',
      offlineNumber: '',
      onlineList:[],
      offlineList:[],
      permanentPeoples: '',
      migrantPopulation: '',
      threeFlag1: 0,
      threeFlag2: 1,
      threeFlag3: true,
      imgFlag: false,
      indexs: 0,
      myPlayer: null,
      base:'',
      value1:'',
      user:'进入后台',
      baseList:[],
      baseObj:{},
      switchList:[
        require("../../../assets/index/open.png"),
        require("../../../assets/index/close.png"),
      ],
      markersList:[],
      flood:[{title:'总流量',value:224,status:0},{title:'通道一流量',value:224,status:0},{title:'通道二流量',value:224,status:0},{title:'通道三流量',value:224,status:1}],
      token:'',
      deviceNumber:'',
      meteorList:[],
      allwater:{},
      soilList:[],
      insectList:[],
      playVideoList:[],
      irrigationList:[],
      soildObj: [],
      meteorObj: [],
      insectObj:{
        deviceName:'',
        deviceLocation:''
      },
      warnList:[],
      SecretObj:{},
      videoUrl:'',
      soildName:'',
      nowDate:'',
      dataTimer:null,
      imgObj: [
        require("../../../assets/index/heat.png"),
        require("../../../assets/index/humidity.png"),
        require("../../../assets/index/windspeed.png"),
        require("../../../assets/index/trend.png"),
        require("../../../assets/index/atmos.png"),
        require("../../../assets/index/beam.png"),
        require("../../../assets/index/rainfall.png"),
        require("../../../assets/index/radiation.png"),
        require("../../../assets/index/i.png"),
      ],
      nameScreen: '',

    };
  },
  beforeMount() {
    window.localStorage.setItem('screenType', 0);

  },
  beforeDestroy(){
    if(this.$refs.myVideo){
      this.$refs.myVideo.load();
    }
  },
  mounted() {

    this.requestLookup()
    this.getBaseList()
    this.getInsectManage()
    //this.getInsect()
    this.getWarnLog()
    // this.getVideo()
    // this.getSecret()
    this.currentTime()
    this.getPowerName();
  },
  methods: {
    getPowerName(){
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if(res.data.state == 'success'){
          this.nameScreen = res.data.datas[0].name;
        }
      })
    },
    toBack(){
      window.localStorage.setItem("marking", "5");
      this.$router.push("/backHome");
    },
    toMonit(){
      this.$router.push("/monitScreen");
    },
    currentTime() {
      this.dataTimer = setInterval(() => {
        this.getDate();
      }, 1000);
    },
    chooseEqment(val){
      // this.soilList.forEach(item=>{
      //   if(val==item.deviceNum){
      //      this.soildObj=item
      //   }
      // })
      // this.getDeviceInfo(val)
      this.getDeviceData(val, 3);
    },
    changStatus(msg,id){
      this.$get(`/deviceCtrl/dataPoint`,{deviceNum:this.SecretObj.deviceNumber,dataPointRelId:id,msg }) .then((res) => {
        if(res.data.state == 'success'){
          this.$message.success("操作成功");
        }
      })
    },
    handlerSelect(val){
      //console.log(val)
      this.baseList.forEach(item=>{
        if(val==item.id){
          this.baseObj=item
          this.getOverview(this.baseObj)
          this.baseGEtData(this.baseObj.baseManagementToDeviceBeanList)
        }
      })
    },
    //设备详情
    getDeviceInfo(val){
      const obj={}
      this.$get(`/deviceManage/getDeviceSensor/${val}`,{}) .then((res) => {
        if(res.data.state == 'success'){
          obj=res.data.data
        }
      })
      return obj
    },
    //告警
    getWarnLog(){
      this.$get(`/warnManage/pageWarnLogs`,{ page:1}) .then((res) => {
        if(res.data.state == 'success'){
          //console.log(res.data,'告警详情')
          this.warnList=res.data.datas
        }
      })
    },

    // 查询基地列表
    getBaseList(){
      const data={
        page:1,
        size:100
      }
      this.$post('/baseManagement/getBaseManagements', data).then(res=>{
        if(res.data.state == 'success'){
          //console.log("基地ALL",res)
          this.baseList=res.data.datas;

          this.base = res.data.datas[0].id;


          // this.imgList = [];
          // let img = res.data.datas[0].imgUrl.split(",");
          // for(let a = 0; a < img.length; a++){
          //   this.imgList.push(img[a])
          // }
          // //console.log(this.imgList)

          if(res.data.datas.length) {
            this.baseObj=res.data.datas[0]
            this.baseGEtData(this.baseObj.baseManagementToDeviceBeanList)
            let list = res.data.datas[0].baseManagementToDeviceBeanList;
            let devId = '';
            for(let a = 0; a < list.length; a++){
              if(list[a].type == 5){
                devId = list[a].deviceNumber.split("_")[1];
                break;
              }
            }
            this.getVideoUrl(devId)
          }
          this.getOverview(this.baseObj)
        }
      })
    },
    baseGEtData(baseList){
      let list=baseList
      if(list.length){
        this.deviceNumber=list[0].deviceNumber
        this.getVillage(list[0].deviceNumber)
        //console.log( this.deviceNumber,list[0].deviceNumber,'list[0].deviceNumber')
      }
    },
    // 获取在线设备
    getOverview(item){
      this.$get('/dptj/sbzl', {
        //key:{id:item.id}
      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data
          //console.log(datas,'在线设备')
          this.totalNumber=datas.totalNumber
          this.onlineNumber=datas.onlineNumber
          this.offlineNumber=datas.offlineNumber
          datas.data.forEach(item=>{
            this.onlineList.push(item.onlinecount)
            this.offlineList.push(item.offlinecount)
          })
          this.initEcharts()
        }
      })
    },
    //获取虫情设备
    getInsectManage(){
      this.$get('/insectManage/page',{ page:1,size:100}).then(res=>{
        if(res.data.state == 'success'){
          if(res.data.datas.length != 0){
            res.data.datas.forEach(item => {
              this.insectList.push(item);
              item['deviceLocation']=item.latitudeAndLongitude
              // this.villageList.push(item)
            });
          }
          if( this.insectList.length){
            this.getInsect(res.data.datas[0].deviceNumber)
            this.$set(this.insectObj,'deviceName',this.insectList[0].name)
            this.$set(this.insectObj,'deviceLocation',this.insectList[0].latitudeAndLongitude)
          }else{
            this.$set(this.insectObj,'deviceName','')
            this.$set(this.insectObj,'deviceLocation','')
          }
        }
      })
    },
    //获取摄像头
    getVideo(){
      this.$get('/cameraManage/page',{ page:1,size:100}).then(res=>{
        if(res.data.state == 'success'){
          res.data.datas.forEach(item => {
            this.playVideoList.push(item);
            /// this.getVideoUrl(item.deviceNum)
          });
          //   if( this.playVideoList.length){
          // this.getVideoUrl(res.data.datas[0].deviceNum)
          //   }
        }
      })
    },
    getPointInfoFor(deviceNumber){
      this.$get('/deviceCtrl/pageDataPointInfoForCusdeviceNo',{ page:1,size:100,deviceNum: deviceNumber}).then(res=>{
        if(res.data.state == 'success'){
          this.irrigationList = res.data.data.cusdeviceDataPointList

        }
      })
    },
    getSecret(){
      this.$get('/deviceCtrl/getSecret').then(res=>{
        if(res.data.state == 'success'){
          //console.log(res.data,'getSecret')
          this.SecretObj=res.data.data
          this.getPointInfoFor(res.data.data.deviceNumber)
        }
      })
    },
    //获取摄像头url
    getVideoUrl(deviceNumId){
      this.$get(`/cameraManage/realPlay/${deviceNumId}?protocol=2`).then(res=>{
        if(res.data.state == 'success'){
          //console.log(res.data.datas,'====获取摄像头地址')
          this.videoUrl= res.data.data.url
          // this.$refs.myVideo.src=res.data.data.url
          const that=this
          setTimeout(() => {
            that.lookCamera(res.data.data.url)
          }, 3000)

        }

      })
    },

    // 获取设备信息
    getVillage(){
      this.$get('/deviceManage/getDeviceInfos',{ page:1,size:100}).then((res) => {
        if(res.data.state == 'success'){
          //console.log(res.data.datas,'====供货')
          res.data.datas.forEach(item => {
            const d=item.deviceNum
            this.allwater[d]=item;
            // this.villageList.push(item)
            if (item.deviceType === '1') {//气象
              this.meteorList.push(item);
            } else if (item.deviceType === '3') {//土壤
              this.soilList.push(item)

            }
          });
          //console.log(this.soilList,"allwater")
          if(this.meteorList.length){
            this.getDeviceData(this.meteorList[0].deviceNum, 1);
          }
          if(this.soilList.length){
            this.soildName= this.soilList[0].deviceNum;
            this.getDeviceData(this.soilList[0].deviceNum, 3);
          }
        }
      })
    },
    // 根据设备号获取设备数据
    getDeviceData(dev, type){
      // let val = {
      //   'deviceNum': dev,
      //   'page': '1',
      //   'size': '100'
      // }
      this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 100,
        deviceNum: dev
      }).then((res) => {
        if(res.data.state == 'success'){
          if(type == 1){
            let datas = res.data.datas[0];
            let keys = [];
            let data = [];
            let dataList = [];
            if(datas.hasOwnProperty("realData")){
              let realData = datas.realData.split("|");
              for(let a = 0; a < realData.length; a++){
                keys.push(realData[a].split("·")[0]);
                data.push(realData[a].split("·")[1]);
              }
              //console.log(keys, data)
              for(let i = 0; i < keys.length; i++){
                dataList.push({name: keys[i], data: data[i]});
              }
              for(let x = 0; x < dataList.length; x++){
                if(dataList[x].name == "温度"){
                  this.$set(dataList[x], "img", this.imgObj[0]);
                }else if(dataList[x].name == "湿度"){
                  this.$set(dataList[x], "img", this.imgObj[1]);
                }else if(dataList[x].name == "风速"){
                  this.$set(dataList[x], "img", this.imgObj[2]);
                }else if(dataList[x].name == "风向"){
                  this.$set(dataList[x], "img", this.imgObj[3]);
                }else if(dataList[x].name == "大气压-k"){
                  this.$set(dataList[x], "img", this.imgObj[4]);
                }else if(dataList[x].name == "光照度"){
                  this.$set(dataList[x], "img", this.imgObj[5]);
                }else if(dataList[x].name == "雨量"){
                  this.$set(dataList[x], "img", this.imgObj[6]);
                }else if(dataList[x].name == "太阳总辐射"){
                  this.$set(dataList[x], "img", this.imgObj[7]);
                }else if(dataList[x].name == "信号强度"){
                  this.$set(dataList[x], "img", this.imgObj[8]);
                }else if(dataList[x].name == "错误码"){
                  this.$set(dataList[x], "img", this.imgObj[8]);
                }else if(dataList[x].name == "版本号"){
                  this.$set(dataList[x], "img", this.imgObj[8]);
                }else{
                  this.$set(dataList[x], "img", this.imgObj[8]);
                }

              }
              //console.log(dataList,"data拆分");
              this.meteorObj = dataList;
            }
          }else{
            let datas = res.data.datas[0];
            let keys = [];
            let data = [];
            let dataList2 = [];
            if(datas.hasOwnProperty("realData")){
              let realData = datas.realData.split("|");
              for(let a = 0; a < realData.length; a++){
                keys.push(realData[a].split("·")[0]);
                data.push(realData[a].split("·")[1]);
              }
              //console.log(keys, data)
              for(let i = 0; i < keys.length; i++){
                dataList2.push({name: keys[i], data: data[i]});
              }
              //console.log(dataList2);
              this.soildObj = dataList2;
            }
          }
        }
      })
    },
    getDetail(id){
      const obj={}
      this.$get(`/insectManage/detail/${id}`,{}) .then((res) => {
        if(res.data.state == 'success'){
          obj=res.data.data
        }
      })
      return obj
    },

    // 获取虫情设备信息
    getInsect(deviceNumber){
      this.$get('/insectManage/pageHist', {
        page:1,
        size:100,
        deviceNumber:deviceNumber
      })
          .then((res) => {
            if(res.data.state == 'success'){
              this.insectObj= Object.assign({},this.insectObj,res.data.datas[0])
              //console.log(this.insectObj,'=====虫情',res.data.datas[0])
            }

          });
    },

    closeBox(){
      this.imgFlag = false;
    },
    getFlag(){
      return !this.threeFlag3 ? 'translations' : ''
    },
    carouselChange(i){
      this.imgFlag = true;
      this.indexs = i;
      if(i == 0){
        // this.lookCamera();
      }
    },
    iframeRotate(rot){
      this.threeFlag1 = rot;
      const iframe = document.getElementById('iframeContainer');
      iframe.contentWindow.postMessage(
          {
            rot:rot
          },"*"
      )
    },

    iframe3d(d){
      this.threeFlag2 = d;
      const iframe = document.getElementById('iframeContainer');
      iframe.contentWindow.postMessage(
          {
            d:d
          },"*"
      )
    },
    clickIMB(){
      this.threeFlag3 = !this.threeFlag3;
    },
    clickRot(){
      if(this.threeFlag1 == 1){
        this.iframeRotate(0);
      }else{
        this.iframeRotate(1);
      }
    },
    click3D(){
      if(this.threeFlag2 == 1){
        this.iframe3d(0);
      }else{
        this.iframe3d(1);
      }
    },

    // 获取任意时间
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "/" + tMonth + "/" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    // 获取星期
    getCurrentWeek() {
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const date = new Date();
      const index = date.getDay(); // 0-6,0 表示星期日
      this.week = days[index];
    },
    // 天气
    requestLookup() {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: { adm: "延安市", location: "富县", key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          let { location } = res.data;
          this.requestweather7d(location);
        }
      });
    },
    // 实时天气
    requestweather7d(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/now",
        params: { location: location[0].id, key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          this.weathers = res.data.now;
          this.text = res.data.now.text;
          this.temp = res.data.now.temp;
          this.windDir = res.data.now.windDir;
          this.windScale = res.data.now.windScale;
        }
      });
    },
    lookCamera(src) {
      this.$nextTick(() => {
        var myVideo = this.$refs.myVideo;
        this.myPlayer = videojs(myVideo, {
          controls: true, //控件显示
          bigPlayButton: true,
          autoplay: 'muted',
          preload: 'auto',
          hls: true,
        });
        this.myPlayer.src({
          src: src,
          type: "application/x-mpegURL"
        });
        //this.myPlayer.play();
        // this.videoArr.push(this.myPlayer);
      })
    },
    // lookCamera(src) {
    //   this.$nextTick(() => {
    //     this.myPlayer = videojs("my-video", {
    //      // autoplay: true, //自动播放
    //       controls: true, //控件显示
    //       bigPlayButton: true,
    //       autoplay: 'muted',
    //       preload: 'auto',
    //       //hls: true,
    //     });
    //     /* this.myPlayer.src({
    //       src: src,
    //       type:'rtmp/flv',
    //      // type: "application/x-mpegURL"
    //     });
    //     this.myPlayer.play(); */
    //   })
    // },


    getBigData(){
      this.$get('/digitalAgriculture/getDigitalAgriculture', {

      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data.data;
          let time = datas.years;
          let names = datas.farmProducts;
          let value = datas.productionValues;
          let dataAll = [];
          for(let a = 0; a < names.length; a++){
            dataAll.push({name: names[a], value: value[names[a]]});
          }
          this.getEcharts2(dataAll, time);
        }
      })
    },
    // 乡村治理
    getGovernance(){
      this.$get('/ruralGovernance/getRuralGovernances', {
        page: 1,
        size: 1000,
        id: '',
        villageId: '',
        type: '',
        year: ''
      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data.datas[0];
          this.totalHouseholds2 = datas.totalHouseholds; // 总户数
          this.totalPeoples2 = datas.totalPeoples; // 总人口数
          this.permanentPeoples = datas.permanentPeoples; // 常驻人口
          this.migrantPopulation = datas.migrantPopulation; // 流动人口
        }
      })
    },
    initEcharts() {
      var myChart = echarts.init(document.getElementById('echart'));
      let option = {
        //  title: { text: '报表展示' },
        grid: {
          left: 40,
          top:30,
          // containLable: true
        },
        tooltip: {},
        xAxis: {
          data: ["气象", "土壤", /*"水质",*/ "摄像头", /*"水肥机",*/ "虫情", /*"袍子"*/],
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#eeeeee', //x左边线的颜色
              fontSize: 13,
              width: '0.5' //坐标线的宽度
            }
          },
          axisLabel: { //x轴字体
            textStyle: {
              color: '#fff',
              fontSize: 13
            }
          },
        },
        yAxis: {
          type: 'value',
          name: "数量（台）",
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#fff', //左边线的颜色
              width: '0.5' //坐标线的宽度
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 13
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#fff'],
              width: 1,
              type: 'dashed'
            }
          }

        },
        legend: {
          itemWidth: 11,
          itemHeight: 12,
          //symbolKeepAspect: true,
          textStyle: {
            color: '#1F6AAB',
            fontSize: 11,
            lineHeight: 0,
            backgroundColor: "rgba(11, 164, 19, 1)"
          },


          data: [
            {
              name: '在线',
            },
            {
              name: '离线',
            }
          ],
          align: 'left',
          right: 40,
          top: '0',
          textStyle: {
            fontSize: 12,
            textAlign: 'center',
            color: '#fff',
            magrinRight: 3
          },
        },
        series: [{
          name: '在线',
          type: 'bar',
          data: this.onlineList ,//[ 2330.0, 4432.9, 7324.0, 2342.2, 2544.6, 7623.7,5643],
          barWidth: 16, //柱子宽度
          barGap: '50%',//间距
          label: {
            //show: true,
            position: 'top',
            textStyle: {
              color: '#4695F3'
            },
            /*  formatter: function (params) {
               return params.value
             } */
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#2fcb8a'
              }, {
                offset: 1,
                color: '#0d3d37'
              }]),
              barBorderRadius: [4, 4, 0, 0],
            }
          },


        },
          {
            name: '离线',
            type: 'bar',
            data: this.offlineList , //[2433.0, 2324.2, 3548.3, 4535.5, 3454.3,5456,6565],
            barWidth: 16,
            barGap: '50%',
            label: {
              // show: true,
              position: 'top',
              textStyle: {
                color: '#FE8401'
              },
              formatter: function (params) {
                return params.value
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#a5c6bb'
                }, {
                  offset: 1,
                  color: '#3a504e'
                }]),
                barBorderRadius: [4, 4, 0, 0],
              },
              emphasis: {
                barBorderRadius: 30
              },
            }
          }
        ]
      }



      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.off('click');
      myChart.on('click', param => {
        //console.log(param)
      });

      window.addEventListener("resize", function () {
        myChart.resize();
      })
    },
    //获取当前时间
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      let week = date.getDay(); // 星期

      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}  `;

    }


  },
};
</script>

<style src="../../../style/font/font.css" scoped></style>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
}

#screenTotal {
  position: relative;
  width: 100%;
  height: 100%;

  // background-size: 100% 100%;
  // background-repeat: no-repeat;

}

#header{
  width: 100%;
  height: 96px;
  position: relative;

  text-align: center;
  overflow: hidden;
  z-index: 45;
  .sun{
    width:30px;
    height: 30px;
    position: relative;
    top:-10px;
    left: 20px;
  }
  p{
    font-size: 38px;
    background: linear-gradient(to bottom, #fff 50%, #B8F9FD 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Title';
    letter-spacing: 4px;
    font-style: normal;
    line-height: 96px;
  }
  .date{
    position: absolute;
    top: 34%;
    left: 0;
    font-size: 14px;
    width: 170px;
    span{
      margin-left: 10px;
      float: right;
    }
  }
  .weather{
    position: absolute;
    top: 34%;
    z-index: 99;
    right: 17.3%;
    font-size: 14px;
    width: 170px;
    height: 40px;
    span{
      margin-right: 10px;
      float: left;
    }
  }
  .user{
    position: absolute;
    top: 30%;
    z-index: 99;
    right: 3%;
    font-size: 14px;
    width: 170px;
    height: 40px;
    cursor: pointer;
    img{
      width: 30px;
      height: 30px;
    }
    span{
      position: relative;
      top:-8px;
      left: 6px;
    }
  }
  .backs{
    position: absolute;
    top: 34%;
    right: 3%;
    font-size: 16px;
    cursor: pointer;
    font-family: 'mokuaiTitile';
    background: linear-gradient(to bottom, #fff 50%, #B8F9FD 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
#main{
  width: 100%;
  height: 100%;
  margin-top: -5%;
  background-image: url(../../../assets/index/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // pointer-events: all;
}
#myMap{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  // pointer-events: none;
  .iframeBox {
    width: 100%;
    height: 100%;
    border: none;
  }
}
#mainBox{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background-image: url(../../../assets/index/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}
.between /deep/.el-range-input{
  background-color: #27474b;
}
.between /deep/ .el-date-editor .el-range-input{
  color:#fff
}
.between /deep/ .el-range-editor.el-input__inner{
  background-color: #27474b;
  width: 100%;
  height: 32px;
  border: #2b8e85 1px solid
}
.translations{
  transform: translateX(-100%);
}
.translations2{
  transform: translateX(100%);
}
#lefts{
  width: 25%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  left: 20px;
  bottom: 0;
  z-index: 47;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}
.center{
  width: 70%;
  height: 95vh;
  margin-top: 11vh;
  /* display: flex;
  flex-direction: column; */
  position: absolute;
  left: 50%;
  bottom: -3vh;
  margin-left: -34.5%;
  .between{
    position: absolute;
    bottom: 53px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 17%;
    .villageInfo{
      position: relative;
      height: 280px;
    }
  }
}
#rights{
  width: 25%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  bottom: 0;
  right:14px;
  z-index:47;

  background-size: 97% 96%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}
.bottoms{
  width: 45%;
  height: 90px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, -50%);

  filter: blur(10px);
}
.leftBoxs{
  width: 92%;
  height: 94vh;
  margin-top: 11%;
  margin-left: 6%;
  pointer-events: all;
}
.rightBox{
  width: 92%;
  height: 94vh;
  margin-top: 11%;
  margin-right: 6%;
  pointer-events: all;
  .villageInfo{
    height: 26vh;
  }
  .survey{
    height:34vh;
    position: relative;
    .equitment{
      img{
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
  .partyBox{
    padding:10px;
    height: 240px;
  }
}
.villageInfo{
  width: 100%;
  height: 26vh;
  overflow: hidden;
  .jidi{
    display: flex;
    justify-content: space-between;

    .jidi-text{
      width: 35%;
      font-size: 16px;
      max-height:280px;
      overflow: auto;
    }
    /deep/.el-carousel__indicator.is-active button{
      width: 30px;
      border-radius: 4px;
      background-color: #80f6fa;
    }
  }
}

.carousel-detail{
  width:40%;
  height: 200px;
  margin-top: 5px;
  border-radius: 8px;
  img{
    width: 100%;
    height: 90%;
    border-radius: 8px;
  }
}
.arrow{
  margin-top: 10px;
  padding: 10px;
  background-image: url(../../../assets/index/chkded.png);
  background-size: 100% 92%;
  background-repeat: no-repeat;
  .jidi-text{
    width: 58% !important;
    display: flex;
    flex-direction: column;
    .update{
      color:#66ffff;
      font-size: 16px;
    }
    .equit{
      margin: 8px 0 2px 8px;
      .arrow-bg{
        position: relative;
        top:3px;
        display: inline-block;
        width:16px;
        height: 16px;
        background-image: url(../../../assets/index/arrow.png);
        background-size: 100% 100%;
      }
      .arrow-key{
        margin-left: 8px;
        font-size: 15px;
        color: #c3fffc;
      }
      .arrow-val{
        font-size: 14px;
        color: #fff;
        margin-left: 5px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 55%;
        height: 16px;
      }
    }

  }
}
.carousel{
  width: 62%;
  height: 26vh;
  margin-top: 5px;
  border-radius: 8px;

  img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #80f6fa;
  }
  /deep/.el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #979797;
  }
}
.el-carousel{
  height:26vh;
}
.el-carousel /deep/.el-carousel__container{
  height: 18vh;
}
.el-carousel__item{
  background-color: transparent;
}
.infos{
  width: 100%;
  height: 180px;
  margin-top: 5px;
  background-size: 100% 100%;
  overflow: hidden;
}
.infoTitle{
  width: 35%;
  height: 35px;
  text-align: center;
  margin: 10px auto 0;
  line-height: 35px;
  font-size: 18px;
  font-family: 'mokuaiTitile';
}
.villageFont{
  width: 95%;
  height: 110px;
  margin: 15px auto 0;
  font-size: 14px;
  font-family: 'mokuaiXi';
  letter-spacing: 1px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.boxTitile{
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  background: url("../../../assets/index/boxtitle.png") no-repeat;
  background-size: 100% 100%;
  font-size: 22px;
  font-family: 'mokuaiTitile';
  font-weight: bold;
  line-height: 45px;
  font-style: normal;

  span{
    width: 85%;
    display: inline-block;
    margin-left: 6%;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .choose{
    color:#c3fffc;
    font-size:16px
  }
  .el-input__inner{
    width:160px
  }
  .el-input__suffix{
    right:20px
  }
}
.base-select{
  width: 40%;
  margin-right: 10px;
  /deep/ .el-input__inner{
    background-color: #0e333b;
    border: 1px solid #113f44;
    color: #fff;
    font-size:14px;
  }
}

.common-box{
  width: 100%;
  // height: 90px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .land-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 260px;
    .equitment{
      width:205px;
      height: 65px;
      display: flex;
      margin-bottom: 5px;
      .land-value{
        display:flex;
        flex-direction: column;
        width:70%;
        .text-gray{
          padding-left: 10px;
          text-align: left;
          line-height:34px;
          background: url(../../../assets/index/poline.png) repeat-x 100% 100%;
        }
        .text-white{
          padding-top: 5px;
          padding-left: 10px;
          text-align: left;
        }
        .number{font-size: 16px}
      }
      .land-icon{
        width:0.3rem;
        height: 0.3rem;
        margin-right: 0.03rem;
      }

      .text-gray{
        font-size:16px;
        color:#b1e1ff;
      }
      .number{
        font-size: 26px;
        color:#fefefe;
      }
      .unit{
        font-size: 16px;
        color:#aac2d2;
      }
    }
  }
}

.surveyBox{
  &:extend(.common-box);
  background: url("../../../assets/index/equipment.png") no-repeat;
  background-size: 90% 90%;
}
.guan{
  flex-wrap: wrap;
  padding:10px 8px;
  height: 1.28rem;
  overflow: auto;
  .guan-list{
    display: flex;
    justify-content: space-between;
    padding:25px 16px;
    width: 1rem;
    margin-bottom: 5px;
    height: 0.6rem;
    background: url("../../../assets/index/guan.png") no-repeat;
    .switch{
      padding-top: 15px;
      span{
        display: inline-block;
        width:66px;
        height: 20px;
        margin-right:14px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
      }
      i{
        position: relative;
        top:-32px
      }
      .open{left:8px}
      .close{left: -8px;}
    }
    .guaname{
      font-size: 16px;
      color:#fff;
    }
    .number{
      font-size: 18px
    }
    .accont{
      font-size: 16px;
    }
  }
}

.holdsAll{
  width: 33%;
  height: 70px;
  text-align: center;
  margin-left: 0.5rem;
}
.moneyAll{
  width: 33%;
  height: 70px;
  text-align: center;
}
.peopleAll{
  width: 33%;
  height: 70px;
  text-align: center;
}
.areaAll{
  width: 33%;
  height: 70px;
  text-align: center;
}
.land{
  padding: 10px 10px 15px;
  /deep/.el-input__inner{height: 32px;}
  .land-list{
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 180px;
    .equitment{
      width:205px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      div{
        flex:1;
        text-align: center
      }
      .text-gray{
        font-size:15px;
        color:#b1e1ff;
        background: url(../../../assets/index/equitment.png) no-repeat 100% 100%;
      }
      .text-white{
        background: url(../../../assets/index/equitment-right.png) no-repeat 100% 100%;
      }
      .number{
        font-size: 18px;
        color:#fefefe;
      }
      .unit{
        font-size: 16px;
        color:#aac2d2;
      }
    }
  }
  .choose{
    color:#c3fffc;
    font-size:16px
  }
  .el-input__inner{
    width:160px
  }
  .el-input__suffix{
    right:20px
  }
}
.surveyBox p{
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'mokuaiTitile';
}
.surveyBox p:nth-child(odd){
  margin-top: 20px;
}
.surveyBox span{
  font-size: 28px;
  /*  background: linear-gradient(to bottom, #fff 40%, #97fcff 60%); */
  color:#fff;
  -webkit-background-clip: text;
  /*   -webkit-text-fill-color: transparent; */
  font-family: 'number';
  margin-right: 5px;
}
.surveyBox .total-color{
  color: #33f19f;
}
.partyBuilding{
  width: 100%;
  height:30vh;
  position: relative;
}
.partyBox{
  width: 100%;
  height: 26vh;
  margin-top: 5px;
}
.leftCard{
  width:100%;
  height: 225px;
}
.member-card{
  display: flex;
  justify-content: flex-start;
  text-align: center;
  height: 34px;
}
.card-bg{
  background: url(../../../assets/index/alarm.png) repeat-x;
}
.member-bg{
  background: url(../../../assets/index/alarm-box.png) repeat-x;
}
.memberTop{
  font-size: 12px;
  line-height: 40px;
}
.memberNum{
  font-size: 24px;
  font-weight: bold;
  margin-left: 68px;
  line-height: 5px;
  font-family: 'number';
}
.memberAll{
  width: 17%;
  height: 20px;
}
.fullMember{
  width: 33%;
  height: 20px;
}
.memberTwo{
  width: 25%;
  height: 20px;
  .alarm-num{
    color: #ff2424;
  }
}
.alarm-scroll{
  height: 180px;
  overflow: auto;
}
.distribution{
  width: 65%;
  height: 225px;
  float: left;
}
.bution{
  width: 100%;
  height: 28px;
  div{
    font-size: 18px;
    margin-left: 20px;
    /*  line-height: 30px; */
    float: left;
    margin-top: -6px;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
  }
}
#echarts1{
  width: 100%;
  height: 175px;
  margin-top: 10px;
}
.links{
  width: 20%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  background: url("../../../assets/index/detail-btn.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 15px;
  font-family: 'mokuaiXi';
  cursor: pointer;
}
.bigData{
  width: 100%;
  height: 235px;
  position: relative;
}
.dataBox{
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts2{
  width: 100%;
  height: 100%;
}
.governance{
  width: 100%;
  height: 235px;
  position: relative;
}
.goverBox{
  width: 100%;
  height: 190px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.goverBox p{
  font-size: 14px;
  // font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 20px;
}
.goverBox p:nth-child(even){
  margin-top: -2px;
}
.goverBox span{
  font-size: 30px;
  color: #80f6fa;
  margin-right: 5px;
  font-family: 'number';
}
.goverItem{
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/allhu.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem2{
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/allren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem3{
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/changren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.goverItem4{
  width: 40%;
  height: 65px;
  background: url("../../../assets/index/liuren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.thirdAsset{
  width: 100%;
  height: 235px;
  position: relative;
}
.thirdBox{
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts3{
  width: 100%;
  height: 100%;
}
.authority{
  width: 100%;
  height: 235px;
  position: relative;
}
.authorityBox{
  width: 100%;
  height: 190px;
  margin-top: 10px;
}
#echarts4{
  width: 100%;
  height: 100%;
}
.controlButton{
  width: 20%;
  height: 30px;
  position: fixed;
  bottom: 2%;
  left: 40%;
  display: flex;
  justify-content: space-between;
}
.rotating{
  width: 25%;
  height: 30px;
  // border: #2ab3b8 1px solid;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  background-color: #1E4C54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
  box-sizing: border-box;
}
// .rotating:hover{
//   background-color: #1E4C54;
// }
.dimensions{
  width: 25%;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  background-color: #1E4C54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
}
// .dimensions:hover{
//   background-color: #1E4C54;
// }
.threeD{
  color: #fff;
  background-color: #979797;
}
.imgBigBox{
  width: 95%;
  height: 85%;
  border: #2ab3b8 1px solid;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(28, 109, 123, 0.7);
  border-radius: 10px;
  z-index: 999;
}
.popUpTop{
  width: 100%;
  height: 50px;
  font-family: 'mokuaiTitile';
  font-size: 20px;
  font-style: italic;
  line-height: 50px;
  background: url("../../../assets/villageRural/tanTop.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  span{
    margin-left: 65px;
  }
  .closeCss{
    margin-left: 0;
    float: right;
    top: 5px;
    right: 20px;
    position: absolute;
    font-size: 30px;
    cursor: pointer;
  }
  img{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.imgInfo{
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #0e343e;
  border: 1px solid #17504d;
}
.carouselBig{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
  }
  .box{
    margin: auto;
  }
  .el-carousel{
    height: 100%;
  }
  .el-carousel /deep/.el-carousel__container{
    height: 100%;
  }
  .el-carousel__item{
    background-color: transparent;
  }
}
/deep/.el-select-dropdown{
  background-color: #0e333b !important;
}
/deep/.el-popper[x-placement^=bottom] .popper__arrow::after{
  border-bottom-color: #0e333b;
}
.el-select-dropdown__item{
  padding-left: 10px;
}
.el-select-dropdown__item.hover{
  background-color: #0e2325;
}

</style>
<style scoped>

</style>