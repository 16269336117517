<template>
    <div id="container"></div>
  </template>
  <script>
  import AMapLoader from '@amap/amap-jsapi-loader';
  import geojson from "../../../assets/geojson/sixianzhen.json"
  export default {
    name: "map-view",
    mounted() {
        this.initAMap();
    },
    unmounted() {
        this.map?.destroy();
    },
    methods: {
       initAMap() {
            window._AMapSecurityConfig = {
                securityJsCode: "56f21d0b10d59d272acc609098c30fbf",
            };
            AMapLoader.reset() 
            AMapLoader.load({
                key: "23d95d6620fa5bd2e8da3f61be5de237",
                version: "2.0",
                plugins: [
                    "AMap.Scale",
                    "AMap.MouseTool",
                    "AMap.Marker",
                    "AMap.ContextMenu",
                    "AMap.Polygon",
                    "AMap.GeoJSON"
                ],
            })
                .then(async(AMap) => {
                    this.map = new AMap.Map("container", {
                        // 设置地图容器id
                        viewMode: "3D", // 是否为3D地图模式
                        zoom: 16.7, // 初始化地图级别
                        layers: [new AMap.TileLayer.Satellite()],
                        center: [109.186973,35.759433], // 初始化地图中心点位置
                    });
                     // 创建多边形实例
                    // var polygon = new AMap.Polygon({
                    //     path: [[116.368904,39.913423],[116.382122,39.901176],[116.387271,39.915887],[116.398258,39.912501]], // 设置多边形坐标数组
                    //     strokeColor: 'black', // 线条颜色
                    //     fillColor: 'red', // 填充颜色
                    //     fillOpacity: 0.1 // 填充透明度
                    // });
                    let dataGeo = []
                    await new AMap.GeoJSON({
                        geoJSON: geojson,
                        getPolygon: function (geojson, lnglats) {
                            dataGeo = lnglats[0]
                        }
                    });
                    //console.log('dataGeo', dataGeo)
                    var polygon = new AMap.Polygon({
                                path: dataGeo, // 设置多边形坐标数组
                                strokeColor: 'yellow', // 线条颜色
                                strokeWeight: 4, //线条宽度
                                fillColor: 'red', // 填充颜色
                                fillOpacity: 0.1 // 填充透明度
                            });
                            polygon.setMap(this.map);
                    
                })
                .catch((e) => {
                //console.log(e);
                });
            },
        },

  }
  </script>
  <style  scoped>
  #container{
      padding:0px;
      margin: 0px;
      width: 100%;
      height: 100vh;
  }
</style>

<!-- [
    [109.49325355, 36.18271625],
    [109.49278836, 36.18257383],
    [109.49198632, 36.18237962],
    [109.49164946, 36.18213362],
    [109.49152113, 36.18190057],
    [109.4919863172519, 36.18166751951104],
    [109.49201839903016, 36.181641624743555],
    [109.492242971443, 36.18121436020497],
    [109.49229109407796, 36.180942463730005],
    [109.49230713496748, 36.180605829072924],
    [109.49193819546312, 36.18056698654656],
    [109.49232317584693, 36.18010087516602],
    [109.49264399260849, 36.18024329829627],
    [109.49296480967233, 36.18050224897229],
    [109.49309313690453, 36.18083888394466],
    [109.49323750622695, 36.1818228879885],
    [109.49341395727049, 36.18248320158245],
    [109.49325355, 36.18271625]] -->